<template>
  <div>
    <div v-if="this.$store.state.token" id="cart" :class="{'no-data': noList}" >
      <div class="cart-header" :style="styles.header">
        <div v-if="noList" class="cart-header-top">
          <!-- 购物车 -->
          <p class="cart-header-title">{{language.l_6}}({{list.length}})</p>
        </div>
        <div v-else class="cart-header-top">
          <!-- 购物车 -->
          <p class="cart-header-title">{{language.l_6}}({{ list.length }})</p>
          <!-- 完成 -->
          <p v-if="isManage" @click="isManage = false">{{language.l_7}}</p>
          <!-- 管理 -->
          <p v-else @click="isManage = true">{{language.l_8}}</p>
        </div>
        <!-- 共  件商品 -->
        <p v-if="!noList" class="cart-header-info">{{language.l_9}}{{list.length}}{{language.l_10}}</p>
      </div>
      <!-- 内容 -->
      <div class="cart-content" :style="styles.content">
        <!-- 有效商品 -->
        <div v-if="list.length > 0" class="goods-lists">
          <van-checkbox-group v-model="result">
            <van-checkbox v-for="item in list" :key="item.id" :name="item.id" label-disabled>
              <div @click.stop="toGoodInfo(item, $event)" class="goods-item">
                <van-card class="goods-card">
                  <template v-slot:thumb>
                    <van-image fit="contain" lazy-load :src="item.pics.length > 0 ? item.pics[0].url : ''"/>
                  </template>
                  <template v-slot:title>
                    <div class="goods-title van-multi-ellipsis--l2">{{item.goods_title}}</div>
                  </template>
                  <template v-slot:desc>
                    <div class="goods-desc">
                      <div class="goods-desc-text van-ellipsis">{{item.product_name}}</div>
                      <div class="goods-desc-wrap">
                        <div class="goods-prices">
                          <div class="goods-prices-pri">${{item.sale_price}}</div>
                        </div>
                        <div class="goods-input">
                          <!-- 步进器 -->
                          <van-stepper @change="updateNum" :name="item.id" integer v-model="item.number" disable-input button-size="25" input-width="38" :max="item.stock"/>
                        </div>
                      </div>
                    </div>
                  </template>
                </van-card>
              </div>
            </van-checkbox>
          </van-checkbox-group>
        </div>

        <!-- 购物车无商品 -->
        <div v-if="noList" class="cart-no">
          <div class="no-data"></div>
          <!-- 哎呀，购物车竟然是空的 -->
          <p>{{language.l_11}}</p>
          <!-- 去逛逛 -->
          <van-button @click="toHome" plain hairline type="info">{{language.l_12}}</van-button>
        </div>
      </div>

      <div v-if="!noList" class="goods-submit van-hairline--bottom">
        <div class="goods-submit-wrap">
          <div class="goods-submit-left">
            <!-- 全选 -->
            <van-checkbox @click="selectAllList" :value="result.length == list.length">{{language.l_13}}</van-checkbox>
          </div>
          <div v-if="isManage" class="goods-submit-right">
            <!-- 删除 -->
            <van-button @click="delSelect" type="danger" plain round>{{language.l_14}}</van-button>
          </div>
          <div v-else class="goods-submit-right">
            <div class="goods-submit-prices">
              <div class="goods-submit-allPri">
                <!-- 合计 -->
                <span>{{language.l_15}}</span>:
                <span>${{ totalPrice.allPrice }}</span>
              </div>
            </div>
            <div class="goods-submit-button">
              <!-- 去结算 -->
              <van-button @click="toOrderCheck" type="default" class="goods-submit-btn">{{language.l_16}}({{result.length}})</van-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else id="erro">
      <img src="@img/cart/no-cart.png" alt="">
      <div class="erro_cent">
        <!-- 登录后可同步购物车中的商品 -->
        <p>{{language.l_17}}</p>
        <!-- 登录 -->
        <van-button @click="toLogin">{{language.l_3}}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], // 有效商品
      result: [], // 选中的商品
      isManage: false,
      language: this.$store.getters.language
    };
  },
  created() {
    this.list = JSON.parse(localStorage.getItem("cartList"));
    if (!this.list) {this.list = []}
  },
  computed: {
    selectGoods() {  // 获取选中的商品数据
      const {list, result} = this;
      let resultData = [];
      result.forEach((item) => resultData.push(...list.filter((detail) => detail.id == item)))
      return resultData;
    },
    // 购物车是否有商品
    noList() {return this.list.length == 0},
    styles() {  // 购物车无商品时的样式
      const {noList} = this;
      let obj = {};
      if (noList) {
        obj = {
          header: {height: "120px"},
          content: {marginTop: "130px"}
        };
      }
      return obj;
    },
    totalPrice() {  // 计算总金额
      const {selectGoods} = this;
      const allPrice = selectGoods.reduce((prev, item) =>prev + parseFloat(item.sale_price) * parseInt(item.number), 0).toFixed(2);
      const allTax = selectGoods.reduce((prev, item) => prev + parseFloat(item.sale_price) * parseInt(item.number) * parseFloat(item.rate), 0).toFixed(2);
      return {allPrice, allTax};
    }
  },
  methods: {
    // 跳转首页
    toHome() {this.$router.push({name: "Home"})},
    // 跳转登录页面
    toLogin() {this.$router.push({name: "Login"})},
    selectAllList() {  // 全选
      const {list, result} = this;
      if (result.length == list.length) {
        this.result = [];
      } else {
        let arr = [];
        list.forEach((item) => arr.push(item.id));
        this.result = arr;
      }
    },
    updateNum() {  // 更改商品数量
      const {list} = this;
      this.$store.commit("addToCart", list);
      localStorage.setItem("cartList", JSON.stringify(list));
    },
    toOrderCheck() {  //提交订单
      let products = this.selectGoods;
      let goods_amount = this.totalPrice.allPrice;

      let params = {
        products,
        goods_amount,
      };

      if(products.length === 0) {return this.$toast(this.language.l_18)}  //请选择商品

      this.$store.commit("setOrderCheck", params);
      this.$router.push({name: "OrderCheck"});
    },
    toGoodInfo(data, e) {  // 跳转商品详情
      if (e.target.className.includes("van-stepper")) return;
      const {goods_id: id} = data;
      this.$router.push({name: "GoodsInfo", params: {id}});
    },
    delSelect() {  //删除
      const {result} = this;
      if (result.length == 0) {
        this.$toast(this.language.l_19);  //请选择要删除的商品哦
        return;
      }
      const idsStr = result.join(",");
      const msg = `${this.language.l_20}${result.length}${this.language.l_21}`;  //是否删除这  件商品？
      this._handleDel(idsStr, msg);
    },
    _handleDel(params, msg) {  // 删除请求
      this.$dialog.confirm({
          message: msg,
          cancelButtonText: this.language.l_22,  //我再想想
          confirmButtonText: this.language.l_14,  //删除
        }).then(() => {
          this.$toast.success(this.language.l_56)  //删除成功
          let {list} = this;
          params.split(",").forEach(item => {
            let arrList = list.filter(item1 => item1.id == item);
            list.splice(arrList, 1);
          });
          this.$store.commit("addToCart", list);
          localStorage.setItem("cartList", JSON.stringify(list));
        }).catch(() => this.$toast.fail(this.language.l_313));  //取消删除
    },
  }
};
</script>

<style lang='less' scoped>
#cart {
  .safe_padding_bottom(100px);
  &.no-data {
    .safe_padding_bottom(50px);
  }
  .cart-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    height: 170px;
    padding: 0 10px;
    font-size: 12px;
    color: #fff;
    background-image: @grad_left;
    .cart-header-top {
      display: flex;
      justify-content: space-between;
      padding: 20px 0 10px;
      font-size: 15px;
      .cart-header-title {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .cart-content {
    margin-top: 85px;
  }
  .goods-lists {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 355px;
    margin: 10px auto 0;
    .van-checkbox-group {
      width: 100%;
    }
    .van-checkbox {
      position: relative;
      align-items: center;
      background-color: #fff;
      margin-bottom: 10px;
      box-sizing: border-box;
      padding: 15px 10px 10px;
      border-radius: 10px;
      justify-content: space-between;
      &:last-child {
        margin-bottom: 0;
      }
      .goods-item {
        width: 305px;
        .van-card__thumb {
          width: 90px;
          height: 90px;
          margin-right: 10px;
        }
        .goods-card {
          background-color: #fff;
          padding: 0;
          .van-image {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
          }
        }
        .goods-title {
          margin-bottom: 5px;
        }
        .goods-desc {
          .goods-desc-text {
            display: inline-block;
            max-width: 100%;
            box-sizing: border-box;
            padding: 0 5px;
            margin-bottom: 5px;
            background-color: #f8f8f8;
          }
          .goods-desc-wrap {
            display: flex;
            justify-content: space-between;
            .goods-prices {
              color: red;
              font-size: 14px;
              text-align: left;
              .goods-prices-tax {
                font-size: 10px;
                color: #ccc;
              }
            }
          }
          .goods-input {
            margin-top: 5px;
          }
        }
      }
    }
  }
  .invalid-lists {
    width: 355px;
    margin: 10px auto 0;
    background-color: #fff;
    box-sizing: border-box;
    padding: 0 10px 10px;
    border-radius: 10px;
    overflow: hidden;
    .invalid-header {
      display: flex;
      justify-content: space-between;
      height: 48px;
      line-height: 48px;
      .invalid-header-left {
        font-size: 14px;
      }
      .invalid-header-right {
        font-size: 12px;
        color: red;
      }
    }
    .invalid-wrap {
      display: flex;
      box-sizing: border-box;
      padding: 10px 0;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 5px;
      }
      .invalid-left {
        align-self: center;
        min-width: 30px;
        padding: 2px 5px;
        font-size: 10px;
        text-align: center;
        color: #fff;
        border-radius: 15px;
        background-color: #a3a3a3;
      }
      .invalid-right {
        position: relative;
        flex: 1;
        .goods-item {
          width: 305px;
          .goods-card {
            background-color: #fff;
            padding: 0;
            padding-right: 10px;
            .van-card__header {
              .van-card__content {
                > div {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  height: 100%;
                  .goods-title {
                    width: 100%;
                    color: #ccc;
                    font-weight: normal;
                  }
                  .goods-desc {
                    // display: inline-block;
                    // max-width: 199px;
                    // box-sizing: border-box;
                    // padding: 2px 10px;
                    // background-color: #F8F8F8;
                    // color: #ccc;
                    // line-height: 1.5;
                    .goods-desc-text {
                      display: inline-block;
                      max-width: 100%;
                      box-sizing: border-box;
                      padding: 2px 10px;
                      line-height: 1.5;
                      color: #ccc;
                      margin-bottom: 5px;
                      background-color: #f8f8f8;
                    }
                  }
                  .goods-foot {
                    display: flex;
                    justify-content: space-between;
                    color: #ff5500;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .cart-no {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    .no-data {
      width: 300px;
      height: 200px;
      margin: 0 auto;
      background-image: url("~@img/cart/no-cart.png");
      background-size: 100%;
      background-position: center;
    }
    p {
      color: #999;
      margin: 10px 0 20px;
    }
    .van-button {
      width: 80px;
      height: 35px;
      line-height: 35px;
      color: @c_light;
      border-color: @c_light;
      background-color: transparent;
    }
  }
  .guangg {
    width: 355px;
    margin: 10px auto 0;
    border-radius: 10px;
    overflow: hidden;
    .van-image {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .goods-like {
    margin-top: 15px;
    margin-bottom: 5px;
    text-align: center;
    span {
      position: relative;
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      .grandient_color(@grad_top);
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 3px;
        width: 18px;
        height: 14px;
        background: url("https://resource.meifanco.com/20200624/077e3c025bd2679fa7ab3c2871204e58.png")
          no-repeat;
        // background: url('https://resource.meifanco.com/20200522/973e7df2ee1be9c667b7ac3dced507f1.png') no-repeat;
        background-size: 100%;
      }
      &::before {
        left: -35px;
      }
      &::after {
        right: -35px;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
  }
  .goods-list {
    box-sizing: border-box;
    padding: 0 10px;
  }
  .goods-submit {
    position: fixed;
    left: 0;
    bottom: calc(49.5px + constant(safe-area-inset-bottom));
    bottom: calc(49.5px + env(safe-area-inset-bottom));
    width: 100%;
    height: 50px;
    background-color: #fff;
    z-index: 99;
    .goods-submit-wrap {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .goods-submit-left {
        font-size: 14px;
        margin-left: 10px;
      }
      .goods-submit-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .goods-submit-prices {
          box-sizing: border-box;
          padding: 5px 0;
          font-size: 12px;
          text-align: right;
          margin-right: 10px;
          .goods-submit-allPri {
            span {
              &:nth-of-type(1) {
                font-size: 14px;
                margin-right: 5px;
              }
              &:nth-of-type(2) {
                color: red;
                font-weight: bold;
              }
            }
          }
          .goods-submit-allTax {
            color: #ccc;
          }
        }
        .goods-submit-button {
          .goods-submit-btn {
            width: 110px;
            height: 50px;
            line-height: 50px;
            color: #fff;
            border: none;
            border-radius: 0;
            background-image: @grad_top;
          }
        }

        & > .van-button {
          height: 30px;
          line-height: 30px;
          margin-right: 10px;
        }
      }
    }
  }
}
#erro {
 text-align: center;
 img{
    width: 100%;
    height: 100%;
 }
 .erro_cent{
   margin-top: -60px;
   p{
     font-size: 14px;
     color: #999999;
   }
     /deep/ .van-button{
       min-width: 120px;
       color: @c_deep;
       margin-top: 20px;
     }
   /deep/ .van-button--default{
     border-radius: 8px;
       border: 1px solid red !important;
     }
 }
}
</style>